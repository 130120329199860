import BaseService from '../BaseService'

const API_ENDPOINTS = {
  getAllNotifications: '/api/notifications/notifications/',
  getSystemNotifications: (page: any) =>
    `/api/notifications/notifications/?is_system=true&page=${page}`,
  getUnreadCounter: '/api/notifications/notifications/?is_read=false&is_system=true',
  getNotificationById: (id: number | string) => `/api/notifications/notifications/${id}/`,
  markAsRead: (id: number | string) => `api/notifications/notifications/${id}/`,
  getAllConnectionRequests: (page: number) =>
    `/api/networking/network/?page=${page}&ordering=-created_at`,
  getAllInvitations: (page: number) =>
    `/api/interactions/invitations/?page=${page}&ordering=-created_at`,
  getPendingInvitations: '/api/interactions/invitations/?status=PENDING',
  getPendingConnectionRequests: '/api/networking/network/?search=Pending&ordering=-created_at',
  getAcceptedConnectionRequests: '/api/networking/network/?search=Accepted&ordering=-created_at',
  getDeniedConnectionRequests: '/api/networking/network/?search=Declined&ordering=-created_at',
  updateRequestStatus: (id: number | string) => `/api/networking/network/${id}/`,
  networkingNotificationsCounter: '/api/networking/received/'
}

const realEstateServices = {
  async getAllNotifications(): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getAllNotifications)
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async getSystemNotifications(page = 1): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getSystemNotifications(page))
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async getUnreadCounter(): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getUnreadCounter)
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async getPendingConnectionRequests(queryParams?: Record<string, any>): Promise<any> {
    try {
      let endpoint = API_ENDPOINTS.getPendingConnectionRequests;
      if (queryParams) {
        const queryString = new URLSearchParams(queryParams).toString();
        if (endpoint.includes('?')) {
          endpoint += `&${queryString}`;
        } else {
          endpoint += `?${queryString}`;
        }
      }
      return await BaseService.get<any>(endpoint);

    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async getAcceptedConnectionRequests(queryParams?: Record<string, any>): Promise<any> {
    try {
      let endpoint = API_ENDPOINTS.getAcceptedConnectionRequests;
      if (queryParams) {
        const queryString = new URLSearchParams(queryParams).toString();
        if (endpoint.includes('?')) {
          endpoint += `&${queryString}`;
        } else {
          endpoint += `?${queryString}`;
        }
      }

      return await BaseService.get<any>(endpoint);
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async getDeniedConnectionRequests(queryParams?: Record<string, any>): Promise<any> {
    try {
      let endpoint = API_ENDPOINTS.getDeniedConnectionRequests;
      if (queryParams) {
        const queryString = new URLSearchParams(queryParams).toString();
        if (endpoint.includes('?')) {
          endpoint += `&${queryString}`;
        } else {
          endpoint += `?${queryString}`;
        }
      }

      return await BaseService.get<any>(endpoint);
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async getNotificationById(id: number | string): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getNotificationById(id))
    } catch (error) {
      console.error(`Error fetching real notification id ${id}:`, error)
      throw error
    }
  },
  async markAsRead(id: number | string): Promise<any> {
    try {
      return await BaseService.patch<any>(API_ENDPOINTS.markAsRead(id), { is_read: true })
    } catch (error) {
      console.error(`Error marking notification id ${id} as read:`, error)
      throw error
    }
  },
  async getAllConnectionRequests(query?: Record<string, any>): Promise<any> {
    try {

      let url = `/api/networking/network/?ordering=-created_at`;

      if (query && Object.keys(query).length > 0) {
        const queryParams = new URLSearchParams(query.params).toString();
        if (queryParams) {
          url += `&${queryParams}`;
        }
      }

      return await BaseService.get<any>(url);
    } catch (error) {
      console.error('Error fetching connection request info:', error);
      throw error;
    }
  },
  async getAllInvitations(page = 1): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getAllInvitations(page))
    } catch (error) {
      console.error('Error fetching connection request info:', error)
      throw error
    }
  },
  async getPendingInvitations(): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.getPendingInvitations)
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
  async acceptRequest(id: number | string): Promise<any> {
    const formData = new FormData()
    formData.append('status', 'accepted')

    try {
      return await BaseService.patch<any>(
        API_ENDPOINTS.updateRequestStatus(id),
        formData,
        'multipart/form-data'
      )
    } catch (error) {
      console.error(`Error marking notification id ${id} as Accept:`, error)
      throw error
    }
  },
  async rejectRequest(id: number | string): Promise<any> {
    const formData = new FormData()
    formData.append('status', 'declined')

    try {
      return await BaseService.patch<any>(
        API_ENDPOINTS.updateRequestStatus(id),
        formData,
        'multipart/form-data'
      )
    } catch (error) {
      console.error(`Error marking notification id ${id} as Reject:`, error)
      throw error
    }
  },
  async revertRequestPending(id: number | string): Promise<any> {
    const formData = new FormData()
    formData.append('status', 'pending')

    try {
      return await BaseService.patch<any>(
        API_ENDPOINTS.updateRequestStatus(id),
        formData,
        'multipart/form-data'
      )
    } catch (error) {
      console.error(`Error marking notification id ${id} as Pending:`, error)
      throw error
    }
  },
  async deleteRequest(id: number | string): Promise<any> {
    try {
      return await BaseService.delete<any>(API_ENDPOINTS.updateRequestStatus(id))
    } catch (error) {
      console.error(`Error deleting request id ${id}:`, error)
      throw error
    }
  },
  async getNetworkingNotifications(): Promise<any> {
    try {
      return await BaseService.get<any>(API_ENDPOINTS.networkingNotificationsCounter)
    } catch (error) {
      console.error('Error fetching notification info:', error)
      throw error
    }
  },
}
export default realEstateServices
