<template>
  <v-menu min-width="300px" rounded style="color: #27293e" content-class="language">
    <template v-slot:activator="{ props }">
      <v-btn class="p-0.5 md:p-1" v-bind="props" color="navbar">
        <v-avatar :image="selectedLanguage.flag" color="brown" size="x-small"> </v-avatar>
        <div class='hidden md:block'>
          <span class="ml-1 text-xs" v-if="selectedLanguage">{{ selectedLanguage.name }}</span>
        </div><v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-card color="cards" class="mt-2">
      <v-list-item color="cards" @click="changeLanguage(item.code)" v-for="(item, i) in languages" :key="i"
                   :value="item">
        <template v-slot:prepend>
          <v-avatar :image="item.flag" size="x-small"></v-avatar>
        </template>
        <v-list-item-title :class="theme.name.value === 'dark' ? 'text-theme-5' : 'text-theme-4'"
                           v-text="item.name"></v-list-item-title>
      </v-list-item>
    </v-card>
  </v-menu>
</template>

<script lang="ts" setup>
import { ref, watchEffect, onBeforeUnmount, onMounted } from 'vue'
import flagEn from '@/assets/images/flags/en.png'
import flagTr from '@/assets/images/flags/tr.png'
import { useAppStore } from '@/store/locale/LocaleStore.ts'
import { useI18n } from 'vue-i18n'
import { useTheme } from 'vuetify'

const appStore = useAppStore()

const selectedLanguage = ref({ flag: flagEn, name: 'English' })

const languages = [
  { code: 'en', name: 'English', flag: flagEn },
  { code: 'tr', name: 'Turkish', flag: flagTr }
]

const { locale } = useI18n()
const theme = useTheme()

const changeLanguage = (code: string): void => {
  appStore.setLocale(code) // Update Pinia store locale
}

// Watch for changes in the app store's locale
watchEffect(() => {
  const newLocale = appStore.currentLocale
  const language = languages.find((lang) => lang.code === newLocale)
  if (language) {
    selectedLanguage.value.flag = language.flag
    selectedLanguage.value.name = language.name
    locale.value = newLocale
  }
})

// Initialize the locale based on the local storage value
const savedLanguage = localStorage.getItem('lang')
if (savedLanguage) {
  appStore.setLocale(savedLanguage) // Initialize Pinia store locale
}

// Watch for changes in localStorage outside of the component
const handleStorageChange = (event: StorageEvent) => {
  if (event.key === 'lang' && event.newValue) {
    appStore.setLocale(event.newValue)
  }
}

// Listen for storage events to update language across components
onMounted(() => {
  window.addEventListener('storage', handleStorageChange)
})

onBeforeUnmount(() => {
  window.removeEventListener('storage', handleStorageChange)
})
</script>
<style scoped>
.v-btn {
  text-transform: none;
  width: 20px;
}
@media only screen and (min-width: 768px) {
  .v-btn {
    width: 155px;
  }
}
</style>
