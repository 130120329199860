import { defineStore } from 'pinia';
import chatServices from '@/services/chatService/ChatServices.ts';
import { useUserStore } from '@/store/user/UserStore.ts';

interface Sender {
  avatar: string;
  email: string;
  id: number;
}

interface SelectedConversation {
  created_at: string;
  message: string;
  sender: Sender;
}

interface ChatState {
  chatRooms: any[];
  roomSocket: WebSocket | null; // WebSocket for chat rooms
  conversationSocket: WebSocket | null; // WebSocket for individual conversations
  messages: any[];
  selectedConversation: SelectedConversation[];
  loading: boolean;
  generateRoomId: number;
  guestAvatar: string;
  guestName: string;
  guestCompanyName: string;
  companyList: any[];
}

export const useChatStore = defineStore({
  id: 'chatStore',
  state: (): ChatState => ({
    chatRooms: [],
    roomSocket: null,
    conversationSocket: null,
    messages: [],
    selectedConversation: [],
    loading: false,
    generateRoomId: 0,
    guestAvatar: "",
    guestName: "",
    guestCompanyName: "",
    companyList: []
  }),
  getters: {
    getSelectedConversation(state) {
      return state.selectedConversation;
    }
  },
  actions: {
    getChatRoomsAction(token: string) {
      const baseURL: string = import.meta.env.VITE_CHAT_BASE_URL as string;
      const wsUrl = `${baseURL}/ws/chat/lobby/?token=${token}`;
      this.roomSocket = new WebSocket(wsUrl);

      this.roomSocket.onopen = () => {
        console.log('WebSocket connection established for chat rooms.');
      };

      this.roomSocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "last_messages") {
          this.chatRooms = data.messages;
        }
      };

      this.roomSocket.onclose = () => {
        console.log('WebSocket connection for chat rooms closed.');
      };

      this.roomSocket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    },

    connectToChat(roomId: number, token: any) {
      this.loading = true;
      this.selectedConversation = [];
      const baseURL: string = import.meta.env.VITE_CHAT_BASE_URL as string;
      const wsUrl = `${baseURL}/ws/chat/${roomId}/?token=${token}`;
      this.conversationSocket = new WebSocket(wsUrl);

      this.conversationSocket.onopen = () => {
        console.log('WebSocket connection established for individual chat.');
      };

      // Handling incoming messages from WebSocket
      this.conversationSocket.onmessage = (event) => {
        this.loading = true;
        const data = JSON.parse(event.data);
        if (data.type == "last_messages") {
          this.addMessageToConversation(data.messages);
        } else {
          this.addMessageToConversation(data);
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      };

      this.conversationSocket.onclose = () => {
        console.log('WebSocket connection for individual chat closed.');
      };

      this.conversationSocket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    },

    sendMessage(message: string) {
      if (this.conversationSocket && this.conversationSocket.readyState === WebSocket.OPEN) {
        this.conversationSocket.send(JSON.stringify({ message }));
      }
    },

    closeRoomConnection() {
      if (this.roomSocket) {
        this.roomSocket.close();
        this.roomSocket = null; // Clear reference after closing
        console.log('Room WebSocket connection closed.');
      }
    },

    closeConversationConnection() {
      if (this.conversationSocket) {
        this.conversationSocket.close();
        this.conversationSocket = null; // Clear reference after closing
        console.log('Conversation WebSocket connection closed.');
      }
    },

    // New action to add message to selectedConversation
    addMessageToConversation(message: any) {
      this.loading = true;
      if (Array.isArray(message)) {
        this.selectedConversation = [...this.selectedConversation, ...message];
      } else {
        this.selectedConversation = [...this.selectedConversation, message];
      }
      this.loading = false;
    },

    async openChat(data: any) {
      const userStore = useUserStore();
      const currentUserId = userStore.id;

      await chatServices.openChatRoom(data).then(res => {
        this.generateRoomId = res.id;

        const otherUser = res.users.find((user: any) => user.id !== currentUserId);
        if (otherUser) {
          this.guestAvatar = otherUser.company_logo;
          this.guestName = otherUser.name + otherUser.last_name;
          this.guestCompanyName = otherUser.company_name;
        }
      });
    },

    async searchCompany(search: any) {
      await chatServices.startNewConversationCompany(search).then(res => {
        this.companyList = res;
      });
    }
  }
});
