import BaseService from '@/services/BaseService.ts'

const chatServices = {
  async getChatRooms(): Promise<any> {
    return await BaseService.get<any>("/api/users/lobby/")
  },
  async chatHistoryService(id: number): Promise<any> {
    return await BaseService.get<any>(`/api/chat/list/?room_id=${id}`)
  },
  async openChatRoom(data: any): Promise<any> {
    return await BaseService.post<any>("/api/chat/rooms/", data)
  },
  async startNewConversationCompany(search: any): Promise<any> {
    return await BaseService.get<any>(`/api/companies/message/?search=${search}`)
  }
}
export default chatServices
