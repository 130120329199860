<template>
  <v-tooltip location="bottom" :text="theme.name.value === 'dark' ? 'light mode' : 'dark mode'">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        :icon="theme.name.value === 'dark' ? 'mdi-weather-sunny' : 'mdi-weather-night'"
        color="navbar"
        :size="checkSize"
        @click="toggleTheme"
      />
    </template>
  </v-tooltip>
</template>

<script lang="ts" setup>
import { useTheme } from 'vuetify'
import { ref, onMounted, onUnmounted } from 'vue'

const theme = useTheme()

const toggleTheme = () => {
  const newTheme = theme.global.current.value.dark ? 'light' : 'dark'
  theme.global.name.value = newTheme
  localStorage.setItem('theme', newTheme)
}

const initializeTheme = () => {
  const savedTheme = localStorage.getItem('theme')
  if (savedTheme) {
    theme.global.name.value = savedTheme
  } else {
    const defaultTheme = 'dark'
    theme.global.name.value = defaultTheme
    localStorage.setItem('theme', defaultTheme)
  }
}

// Reactive `checkSize` to handle resizing
const checkSize = ref('small')

const updateSize = () => {
  checkSize.value = window.innerWidth < 1024 ? 'x-small' : 'small'
}

onMounted(() => {
  updateSize() // Set initial size
  window.addEventListener('resize', updateSize) // Listen to resize events
})

onUnmounted(() => {
  window.removeEventListener('resize', updateSize) // Clean up event listener
})

initializeTheme()
</script>
