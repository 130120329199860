import { defineStore } from 'pinia'
import CompanyService from '../../services/companyServices/CompanyServices'
import { RealEstate } from '@/interfaces/RealEstateInterface.ts'
import { MarketplaceOffer, MarketplaceRequests } from '@/interfaces/MarketplaceInterfaces'
import { CompanyDetails } from '@/interfaces/NetworkingInterface'
import {
  EventAnnouncement,
  OfferAnnouncement,
  NewProjectAnnouncement
} from '@/interfaces/AnnouncementInterfaces.ts'
import companyServices from '../../services/companyServices/CompanyServices'

interface CompanyServices {
  id: number
  name: string
  name_tr: string,
  name_en: string
}

interface CompanyProfileResponse {
  id: number
  company_name: string
  email: string
  phone_number: string
  description: string
  logo: string
  services: CompanyServices[]
  country: string
  city: string
  address: string
  latitute: string
  longitute: string
  marketplace_offer: MarketplaceOffer[]
  marketplace_request: MarketplaceRequests[]
  realestate: RealEstate[]
  related_companies: CompanyDetails[]
  event_announcements: EventAnnouncement[]
  offer_announcements: OfferAnnouncement[]
  new_project_announcements: NewProjectAnnouncement[]
  youtube_link: string
  banner_image: string
  photos: any[]
  videos: any[]
  is_golden_user: boolean
  is_promotion: boolean
  promotion_createdAt: string
}

interface CompanyState {
  id: number
  company_name: string
  email: string
  phone_number: string
  description: string
  logo: string
  services: CompanyServices[]
  country: string
  city: string
  latitute: string,
  address: string,
  longitute: string,
  realestate: RealEstate[]
  related_companies: CompanyDetails[]
  event_announcements: EventAnnouncement[]
  offer_announcements: OfferAnnouncement[]
  new_project_announcements: NewProjectAnnouncement[]
  marketplace_offers: MarketplaceOffer[]
  marketplace_requests: MarketplaceRequests[]
  youtube_link: string
  banner_image: string
  photos: any[]
  videos: any[],
  staff: any[],
  languages: any[],
  staffEmail: string,
  inviter_company: string
  is_golden_user: boolean
  is_promotion: boolean
  promotion_createdAt: string
}

interface CompanyActions {
  fetchCompany(id: number | string): Promise<CompanyProfileResponse>
  updateCompany(data: FormData | Record<string, any>): Promise<void>
  deleteCompanyMedia(id: number): Promise<void>
  getStaff(): Promise<void>
  staffInvitation(data: any): Promise<void>
  getEmailAndCompanyName(data: any): Promise<void>
  getLanguages(): Promise<void>
  registerStaff(data:any, id:any): Promise<void>
  deleteUserFromStaff(id: any): Promise<void>
}

export const useCompanyStore = defineStore<'companyStore', CompanyState, {}, CompanyActions>({
  id: 'companyStore',
  state: () => ({
    id: 0,
    company_name: '',
    email: '',
    phone_number: '',
    description: '',
    logo: '',
    services: [],
    country: '',
    city: '',
    latitute: "",
    address: "",
    longitute: "",
    realestate: [],
    related_companies: [],
    event_announcements: [],
    offer_announcements: [],
    new_project_announcements: [],
    marketplace_offers: [],
    marketplace_requests: [],
    youtube_link: '',
    banner_image: '',
    photos: [],
    videos: [],
    staff: [],
    languages: [],
    staffEmail: "",
    inviter_company: "",
    is_golden_user: false,
    is_promotion: false,
    promotion_createdAt: "",
  }),
  getters: {
    filterRealEstateByType: (state) => {
      return (typeName: string) => {
        return state.realestate.filter((realestate) =>
          realestate.realestate_type.some((type) => type.name === typeName)
        )
      }
    }
  },
  actions: {
    async fetchCompany(id: number | string) {
      const response = (await CompanyService.getCompanyInfo(id)) as CompanyProfileResponse
      this.realestate = response.realestate
      this.$patch({
        id: response.id,
        company_name: response.company_name,
        email: response.email,
        phone_number: response.phone_number,
        description: response.description,
        logo: response.logo,
        services: response.services,
        country: response.country,
        city: response.city,
        marketplace_offers: response.marketplace_offer, // Added
        marketplace_requests: response.marketplace_request,
        latitute: response.latitute,
        longitute: response.longitute,
        address: response.address,
        youtube_link: response.youtube_link,
        banner_image: response.banner_image,
        photos: response.photos,
        videos: response.videos,
        event_announcements: response.event_announcements,
        offer_announcements: response.offer_announcements,
        new_project_announcements: response.new_project_announcements,
        is_golden_user: response.is_golden_user,
        is_promotion: response.is_promotion,
        promotion_createdAt: response.promotion_createdAt
      })
      return response
    },
    async updateCompany(data: FormData | Record<string, any>) {
      const response = (await CompanyService.updateCompanyInfo(
        this.id,
        data
      )) as CompanyProfileResponse
      this.$patch({
        company_name: response.company_name,
        phone_number: response.phone_number,
        email: response.email,
        description: response.description,
        logo: response.logo,
        services: response.services,
        latitute: response.latitute,
        longitute: response.longitute,
        address: response.address,
        youtube_link: response.youtube_link,
        banner_image: response.banner_image,
        photos: response.photos,
        videos: response.videos
      })
    },
    async deleteCompanyMedia(id: any) {
        await companyServices.deleteMediaInCompanyProfile(id)
    },
    async getStaff(this: CompanyState){
      await companyServices.getStaffCompany().then((res) => {
        this.staff = res.staff
      })
    },
    async staffInvitation(data: any){
      await companyServices.inviteStaff(data)
    },
    async getEmailAndCompanyName(this: CompanyState,data: any) {
      await companyServices.getInformation(data).then((res) => {
        this.staffEmail = res.email
        this.inviter_company = res.inviter_company
      })
    },
    async getLanguages(this: CompanyState) {
      await companyServices.getLanguageInformation().then((res) => {
        this.languages = res.data
      })
    },
    async registerStaff(data:any, id: any) {
      await companyServices.registerUserStaff(data, id)
    },
    async deleteUserFromStaff(id:any) {
      await companyServices.deleteStaff(id)
    }
  }
})
