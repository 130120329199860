import BaseService from '../BaseService'

const UserService = {
  // Method for getting user info
  async getUserInfo(): Promise<any> {
    return await BaseService.get<any>('/api/users/info/')
  },
  // Method for updating user info
  async updateUserInfo(data: any): Promise<any> {
    return await BaseService.patch<any>('/api/users/update/', data, 'multipart/form-data')
  },
  //Method for updating user by Id
  async updateUserById(id: number, data: FormData): Promise<any> {
    return await BaseService.put<any>(`/api/users/update/${id}/`, data, 'multipart/form-data');
  }
}

export default UserService
